<template>
  <el-dialog
    title="任职状态变更"
    :visible.sync="show"
    width="500px"
    :before-close="beforeClose"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-radio-group v-model="rzzt">
      <el-radio v-for="item in options.rzzt.children" :key="item.id" :label="item.value">{{item.label}}</el-radio>
    </el-radio-group>
    <div slot="footer">
      <el-button type="danger" @click="submit" size="small" :loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import PeopleJury from '@/api/choose/peopleJury';
import Public from '@/api/public';
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      show: false,
      data: '',
      rzzt: '',
      bgztsm: '',
      options: {
        rzzt: {
          children: [],
        },
      },
    };
  },
  methods: {
    beforeClose(done) {
      done();
      this.$emit('changeShow', false);
    },
    toggle(show, data) {
      this.show = show;
      if (data) {
        console.log(data);
        this.data = data;
        this.rzzt = data.rzzt;
      }
    },
    submit() {
      this.loading = true;
      PeopleJury.statusUpdate({
        sfzhm: this.data.sfzhm,
        rzzt: this.rzzt,
        bgztsm: this.bgztsm,
      })
        .then(() => {
          this.$emit('getList');
          this.toggle(false);
          this.$message.closeAll();
          this.$message.success('任职状态变更成功');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    Public.components({
      Dictionary: ['rzzt'],
    }).then((res) => {
      this.options = res.Dictionary;
    });
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
